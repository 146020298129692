import React from 'react';
import Layout from '../components/layout/layout';
import * as styles from '../components/blog.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Commentsimg from '../images/comments.svg';
import ProfileImg from '../images/profile.svg';
import BlogImg from '../images/blogImg.png';
import Facebook from '../images/facebookBlue.png';
import Twitter from '../images/twitterBlue.png';
import Linkedin from '../images/linkedinBlue.png';
import MailBlue from '../images/mailBlue.png';
import { FacebookProvider, Comments } from 'react-facebook';
import FeaturedPosts from '../components/featuredPosts';
import CardsRowImg3 from '../images/cardsRowImg3.png';
import DavidImg from '../images/David.png';

class BradenWinstheSnowball extends React.Component{
  constructor(props){
    super(props);
    this.state={

    }
  }
  
  render(){
    return(
      <Layout>
        <div id="fb-root"></div>
          <div className={styles.landingSection}>
            <div className={styles.landingSectionSubDiv}>
              <text className={styles.blogHeader}>Braden Helps Promote Virtual Racing</text>
              <div className={styles.subtitle}>
                <div className={styles.profileCol2}>
                  <img className={styles.ProfileImg} src={ProfileImg} alt="landingimg" />
                  <text className={styles.profileTest}>By Cody Tomer</text>
                  <p>17 Aug, 2019</p>
                </div>
                <div className={styles.profileCol2} id={styles.profilecolId}>
                  <img className={styles.ProfileImg} src={Commentsimg} alt="landingimg" />
                  <text className={styles.profileTest}>0 Comments</text>
                </div>
              </div>
              <text className={styles.blogDesc}>
                <div className={styles.subblogDesc}>
                  <text>Pro driver Wheeling native is at Jerry' Bar this weekend</text>
                </div>
              </text>
              <img className={styles.blogImg} src={CardsRowImg3} alt="landingimg" />
              <text className={styles.blogDesc}>
                <p>Photo by Cody Tomer Wheeling native and pro race car driver Travis Braden was at Jerry’s Bar in McMechen on Friday to promote virtual reality game betting. Pictured in front of his race car are, from left, Saber VR’s Elijah Roach, bar owner and PropMe LLC co-founder Jake Padlow, Braden and PropMe LLC co-founder Dave Ealy Jr.</p>
                <p>MCMECHEN — Pro race car driver and Wheeling native Travis Braden grew up wishing that video game technology would be more realistic — especially when it came to racing.</p>

                <p>That time has come.</p>

                <p>This weekend, Braden, a two-time ARCA/CRA Super Series champion, is visiting Jerry’s Bar in McMechen to help promote virtual reality game betting.</p>

                <p>Braden joined bar owner and co-founder of PropMe LLC, Jake Padlow, Dave Ealy Jr., co-founder of PropMe LLC, and Elijah Roach of Saber VR to work on a plan for making virtual reality racing and betting in restaurants and bars in West Virginia a thing of the future.</p>

                <p>“When you’re a kid, if you’re in that generation that played video games — in racing, you always dream about advanced technology where it can be in 3D with a virtual reality headset,” Braden said. “It never really happened as quick as we thought it would but now it’s more advanced than we would have even dreamed of back then. It’s not just a video aspect of it but the seat moves and all this stuff. You can really feel it.”</p>

                <p>Braden will be at Jerry’s both Saturday and Sunday from noon-6 p.m., along with his race car, and will be taking on challengers in the life-like race simulators.</p>

                <p>After testing it out on Friday, Braden was quite impressed.</p>

                <p>“It was good,” Braden said. “I got used to it after awhile. I’d like to try different tracks and maybe a road course, too.”</p>

                <p>The main goal of the weekend is to pull all the sides together and come up with a gameplan moving forward.</p>

                <p>“We’re trying to pull in Elijah’s technology of virtual simulation racing with a known name (Braden) and our technology (PropMe LLC) and trying to mix it up so it’s a win/win for everybody,” Ealy Jr. said. “We want to roll this out throughout the state and hopefully it will grow for larger adoption. That’s what this weekend is — everybody meet up to talk about potential.</p>

                <p>“We’re thankful that Travis is here and thankful that Elijah came down from New Jersey.</p>

                <p>“We hope to grow geolocation betting to the race tracks and put these in bars and put these in regional locations where advertisers can buy in and have some sponsorships for Travis. It could be a ripple effect for what may happen (Friday).</p>

                <p>Sunday morning we will leave with a good gameplan with what everybody wants out of it and what everybody is most interested in.”</p>

                <p>As far as the betting goes, it’s the perfect time to make it happen.</p>

                <p>“We were talking to Elijah and gave him the idea that we can bet on this stuff,” Padlow said. “(Saber VR) has the capabilities of doing all of these things we want to do and we have the capabilities with our technology of blending it.</p>

                <p>“We’ve been trying to integrate our betting into eSports for about two years now. We had a Fortnite event down here and also a Madden event that drew some potential for what I see with virtual reality.</p>

                <p>“The first thing Elijah told me when I talked to him about this was ‘we need a driver.'”</p>

                <p>Enter Braden.</p>

                <p>“Jake gave me a call and asked if I was available to come do this,” Braden said. “I was like ‘OK’. I thought it was really neat and just decided to come be a part of it during the weekend to help promote it.”</p>

                <p>Roach thought Braden was the perfect person to be a part of this event.</p>

                <p>“(Jake) called me and said ‘how do we kick this off?’ I said the first thing we have to do is get local talent,” Roach said. “Someone who is doing what we are doing but people trust and will come out to see him.”</p>

                <p>Along with bringing Braden aboard, virtual racing has also caught the attention of people of all ages who are eager to see what it’s like to get behind the wheel and barrel down the stretch at Daytona.</p>

                <p>“There was a 90-year old guy come in for his birthday and raced for an hour,”Roach said. “He loved it. It’s all about the experience. I don’t call them games. We’re in the experience business. Our goal is to take one person or group of people and give them something exciting.</p>

                <p>“Look at football and how many people are in the NFL vs. how many people play football or want to play football. That’s who you have to appeal to. How many pro drivers are there vs. people who just like racing. The experience is how you capture the new gambler.”</p>
                <p>Source:  <a href="http://www.theintelligencer.net/sports/top-sports/2019/08/braden-helps-promote-virtual-racing/
                ">http://www.theintelligencer.net/sports/top-sports/2019/08/braden-helps-promote-virtual-racing/</a></p>
              </text>
              <div className={styles.oldPostsLink}>
                <a className={styles.servicelinks} href="/braden-wins-the-snowball"><text>{'<'}Older Post</text></a>
                <a className={styles.servicelinks} href="/braden-coming-to-mcmechen"><text>Newer Post{'>'}</text></a>
              </div>
              <div className={styles.BlogsocialLinksDiv}>
                <img className={styles.social} src={Facebook} alt="landingimg" />
                <img className={styles.social} src={Twitter} alt="landingimg" />
                <img className={styles.social} src={Linkedin} alt="landingimg" />
                <img className={styles.social} src={MailBlue} alt="landingimg" />
              </div>
              <iframe name="f297624ad60196" width="500px" height="100px" data-testid="fb:comments Facebook Social Plugin" title="fb:comments Facebook Social Plugin" frameborder="0" allowtransparency="true" allowfullscreen="true" scrolling="no" allow="encrypted-media" src="https://www.facebook.com/v2.0/plugins/comments.php?app_id=691895914788460&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df247eedd96682d4%26domain%3Dfriendly-curran-11b640.netlify.app%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Ffriendly-curran-11b640.netlify.app%252Ffe72a2fa7cef6c%26relation%3Dparent.parent&amp;color_scheme=light&amp;container_width=714&amp;height=100&amp;href=https%3A%2F%2Fwww.facebook.com%2Fblan%2F&amp;locale=en_US&amp;numposts=6&amp;sdk=joey&amp;version=v2.0&amp;width=500" style={{border: 'none', visibility: 'visible', width: '500px', height: '458px'}} class=""></iframe>
              <div className={styles.featuredNewsDiv}>
                <text>Featured News</text>
              </div>
              </div>
            </div>
          <div>
          <FeaturedPosts/>
        </div>
      </Layout>
    )
  }
}

export default BradenWinstheSnowball